import Vue from 'vue'
import axios from 'axios'

export default {
  actions: {
    async login({ dispatch, commit }, { login, password, model }) {
      try {
        const workspace = model.replace('_', '')
        // http://pb-01.nmgdev.ru:16051/v2/user/signin
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/v2/user/signin`,
          data: JSON.stringify({
            email: login,
            password: password,
            workspace: workspace,
            reset: false,
          }),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async getConfirmCode({ dispatch, commit }, { login, model }) {
      try {
        const workspace = model.replace('_', '')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/v2/user/password/change`,
          data: JSON.stringify({
            email: login,
            workspace: workspace,
          }),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async recovery(
      { dispatch, commit },
      { login, model, password, password_confirmation, confirmation_code },
    ) {
      try {
        const workspace = model.replace('_', '')
        console.log({
          email: login,
          workspace: workspace,
          password: password,
          password_confirmation: password_confirmation,
          confirmation_code: confirmation_code,
        })

        const response = await axios({
          method: 'post',
          url: `${this.state.url}/v2/user/password/change`,
          data: JSON.stringify({
            email: login,
            workspace: workspace,
            password: password,
            password_confirmation: password_confirmation,
            confirmation_code: confirmation_code,
          }),
        })
        console.log(response)
        return response.data
      } catch (e) {
        throw e
      }
    },
    async logout({ dispatch, commit }) {
      try {
        Vue.cookie.delete('token')
        Vue.cookie.delete('model')
      } catch (e) {
        throw e
      }
    },
  },
}
